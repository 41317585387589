import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Kotlin Fest 2018 にメドレーが\"ひよこスポンサー\"として協賛しました",
  "date": "2018-09-03T03:00:00.000Z",
  "slug": "entry/2018/09/03/120000",
  "tags": ["medley"],
  "hero": "./2018_09_03.png",
  "heroAlt": "kotlin fest 2018"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部平木です。去る 8/25 に行われた、日本初(!!)の Kotlin の言語カンファレンスである`}<a parentName="p" {...{
        "href": "https://kotlin.connpass.com/event/91666/"
      }}>{`Kotlin Fest 2018`}</a>{`に弊社は"ひよこスポンサー"として協賛させていただきました。`}</p>
    <p><em parentName="p">{`公式 Twitter で紹介された様子`}</em></p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">スポンサーのご紹介です。Medley (<a href="https://twitter.com/medley_life?ref_src=twsrc%5Etfw">@medley_life</a>) 様に Kotlin Fest のひよこスポンサーになっていただきました！よろしくお願いします！ <a href="https://twitter.com/hashtag/kotlinfest?src=hash&amp;ref_src=twsrc%5Etfw">#kotlinfest</a><a href="https://t.co/RojsiqlGMi">https://t.co/RojsiqlGMi</a></p>&mdash; Kotlin Fest (@kotlin_fest) <a href="https://twitter.com/kotlin_fest/status/1032930515731410944?ref_src=twsrc%5Etfw">August 24, 2018</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`今回スポンサーチケットで参加させていただいたので、つれづれとレポートを書いてまいります。`}</p>
    <h1>{`メドレーと Kotlin の関わり`}</h1>
    <p>{`なぜ、メドレーが Kotlin Fest に協賛したかというと Kotlin を使って Android アプリを作っているからになります。`}</p>
    <p>{`オンライン診療アプリ CLINICS の Android 版で使っています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=life.medley.clinics"
      }}>{`https://play.google.com/store/apps/details?id=life.medley.clinics`}</a></p>
    <p>{`Android で正式に Kotlin サポートすることが`}<a parentName="p" {...{
        "href": "https://android-developers.googleblog.com/2017/05/android-announces-support-for-kotlin.html"
      }}>{`アナウンス`}</a>{`されてからできるところを Java から Kotlin に書きかえていっています。`}</p>
    <p>{`方針としては、ムリに全部のソースを Kotlin にするという形ではなく改修などで触ったソースで余力があれば書きかえるというスタンスでやっていますが、それでも現在 50%弱のソースが Kotlin になっています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://d2mxuefqeaa7sj.cloudfront.net/s_024FF11B0CBA409D403D1FFC8B358DB011B011904F47178CD795ECA7FE629F5C_1535525616476_screenshot.png",
        "alt": null
      }}></img></p>
    <p>{`Kotlin で書いた場合に Java よりも可読性や堅牢性が上がるというメリットを実感していたところ、今回の Kotlin Fest の開催を知り、協賛させていただいたという次第です。`}</p>
    <h1>{`イベントの様子`}</h1>
    <p>{`会場は`}<a parentName="p" {...{
        "href": "https://www.tokyo-cc.co.jp/shinagawa/index.html#access"
      }}>{`東京コンファレンスセンター品川`}</a>{`でした。自分は初訪問だったのですが、設備も充実しており良い会場だと思いました。`}</p>
    <p>{`無限に出てくるかのようなコーヒー・飲み物とお菓子が大変ホスピタリティを感じさせます。`}</p>
    <p>{`スポンサーブースも盛況で、なかでも Yahoo! Japan さんのモブプロ実演や、CyberAgent さんの Kotlin クイズなどが人気を集めていました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180903/20180903111847.jpg",
      "alt": "20180903111847.jpg"
    }}></img>
    <p><strong parentName="p">{`M3 さんのブースでいただいたロゴ入りじゃがりこ`}</strong></p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180903/20180903111902.jpg",
      "alt": "20180903111902.jpg"
    }}></img>
    <h1>{`セッション`}</h1>
    <p>{`セッションは 2 セッションが同時に行われるという形式でした。`}</p>
    <p>{`自分が参加したセッションのみですが簡単な感想でご紹介します。`}</p>
    <h2>{`Kotlin で改善する Android アプリの品質`}</h2>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/f8df5829e1934f2a885fbbe24fb6c7b0" title="KotlinFest.pdf" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "420px"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`Java から Kotlin への書きかえを考えた場合のアプリの品質を主軸にしてメリットを紹介する…というものでした。`}</p>
    <p>{`Effective Java の中で紹介されている項目について、Kotlin ではどうなるかという視点での紹介は大変興味深かったです。`}</p>
    <p>{`Kotlin は Java よりも Null 安全を始め、堅牢だというイメージがありましたが、こうして Java で`}<strong parentName="p">{`するべきである`}</strong>{`という項目が Kotlin では言語仕様レベルで対応されていることが多いというのを目の当たりにすると、さらに頼もしく思えるというようなセッションでした。`}</p>
    <h2>{`Kotlin アプリのリファクタリングポイント`}</h2>
    <iframe src="//www.slideshare.net/slideshow/embed_code/key/crHCtpqwXf4ABn" width="595" height="485" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no" style={{
      "border": "1px solid #CCC",
      "borderWidth": "1px",
      "marginBottom": "5px",
      "maxWidth": "100%"
    }} allowFullScreen> </iframe> <div style={{
      "marginBottom": "5px"
    }}> <strong> <a href="//www.slideshare.net/RecruitLifestyle/refactoring-point-of-kotlin-application" title="Refactoring point of Kotlin application" target="_blank">Refactoring point of Kotlin application</a> </strong> from <strong><a href="//www.slideshare.net/RecruitLifestyle" target="_blank">Recruit Lifestyle Co., Ltd.</a></strong> </div>
    <p>{`既に存在する Kotlin のコードをどのような指針でリファクタリングしていくかというセッションです。`}</p>
    <p>{`「こういうときに書き方複数あるけどどうしよう…」という例ばかりだったので、自分達のアプリでもすぐに使えるような実践的なセッションでした。`}</p>
    <p>{`中でもいかに`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Mutable`}</code>{`なプロパティを避けるかというフローチャートは、理路整然としていてこれから Kotlin を書いていく上でかなり参考になりました。`}</p>
    <h2>{`Kotlin linter`}</h2>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/7b962f63e61c42f6b42db2a8482be3d7" title="kotlin linter" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "420px"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`自分の場合、JavaScript などでもわりと Linter は興味がある分野だったのですが、Kotlin の Lint について
は android-lint しか使ったことがなかったので、紹介されている Linter の情報が参考になるセッションでした。`}</p>
    <p>{`Kotlin の Linter もやはり AST を触らないとオリジナルルールの設定ができないのかーなど普段触れていなかった知識が得られて有意義でした。`}</p>
    <p>{`が、Kotlin の AST は`}<a parentName="p" {...{
        "href": "https://plugins.jetbrains.com/plugin/227-psiviewer"
      }}>{`PsiViewer`}</a>{`という IntliJ プラグインくらいしか対応してなさそうで、自分で設定するとなると若干つらそうだなという印象でした。`}</p>
    <p><a parentName="p" {...{
        "href": "https://astexplorer.net/"
      }}>{`AST Explore`}</a>{`あたりで気軽に試せると良いですね。`}</p>
    <h2>{`Kotlin で愛でる Microservices`}</h2>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/8b7d8bd62d2b4a64966d3762c1923678" title="kotlin-fest" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`サーバサイド Kotlin を Microservice でガンガン使用するために必要なエッセンスがまとまったセッションでした。`}</p>
    <p>{`元々使っていた Go との使いわけや、実際どのようなアーキテクチャで作って、デプロイや監視など運用をどのようにしているのかなどがコンパクトにまとまっていて大変分かりやすかったです。`}</p>
    <p>{`サーバサイド Kotlin は弊社で使う予定は現状まだ無いのですが、このセッションを見た限りミニマムに始めることが可能な感じに思えたのが収穫でした。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`Kotlin の日本初のカンファレンスでしたが、来場者もかなり多く Kotlin エンジニアの裾野が広いなという印象でした(セッションによっては立ち見も出ていました)。`}</p>
    <p>{`またセッションも初級から上級まで幅広く取り揃えられていたので、飽きることなく楽しめましたし、来年以降も続いていってほしいと思ったカンファレンスでした。`}</p>
    <p>{`メドレーでは今後も色々な Tech カンファレンスをスポンサードして参ります。色々な場所で、お会いできたらと思います！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      